import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useSearchParams } from 'react-router-dom';
import { Header, Input } from '@hubportal/components';
import { HubsMultiselect } from 'partials/multiselect';
import useDebounce from 'utils/hooks/useDebounce';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import useStore from 'utils/store';
import waitPermissions from 'utils/hoc/waitPermissions';
import { Calendar } from 'components/calendar';
import { getPastCalendarWeeks } from 'utils/helpers';
import { format, isToday } from 'date-fns';
import {
  isLatenessStrikesGenerationEnabled,
  isLateSickNoteStrikesGenerationEnabled,
} from 'utils/eppo/helpers';
import { Notifications } from 'partials/notifications';

const WorkforceIndexFC = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedHub } = useStore();
  const { isAllowed } = usePermissions();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(
    searchParams?.get('search') || ''
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [selectedHubs, setSelectedHubs] = useState<string[]>(
    isAllowed(Permission.READ_HUBS_ALL)
      ? searchParams?.get('hubs')?.split(',').filter(Boolean) || []
      : [selectedHub?.slug]
  );

  const [updatedAt, setUpdatedAt] = useState();

  const periods = useMemo(() => {
    return getPastCalendarWeeks(10, true);
  }, []);

  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(
    searchParams?.get('cw')
      ? Math.max(
          periods.findIndex((p) => p.cw === Number(searchParams?.get('cw'))),
          0
        )
      : 0
  );

  const selectedPeriod = useMemo(
    () => periods[selectedPeriodIndex],
    [periods, selectedPeriodIndex]
  );

  useEffect(() => {
    setSearchParams(
      {
        hubs: selectedHubs.join(','),
        search: searchTerm,
        cw: `${selectedPeriod?.cw || 0}`,
      },
      { replace: true }
    );
  }, [selectedHubs, searchTerm, selectedPeriod]);

  const isComplianceAllowed =
    isAllowed(Permission.READ_COMPLIANCE_ALL) ||
    isAllowed(Permission.READ_COMPLIANCE_ALLOWED) ||
    isAllowed(Permission.READ_COMPLIANCE_LIMITED) ||
    isAllowed(Permission.READ_PERFORMANCE_LIMITED);

  const lastUpdatedAt = useMemo(() => {
    const date = updatedAt ? new Date(updatedAt) : '';
    return date
      ? isToday(date)
        ? format(date, 'HH:mm')
        : format(date, 'dd.MM.yyyy HH:mm')
      : '-';
  }, [updatedAt]);

  /**
   * HMs -> compliance_allowed permission and don't see the hub-select -> selected hub
   * People ops -> compliance_all permission and see the hub-select -> all hubs
   * Admins -> compliance_allowed permission and see the hub-select -> allowed hubs
   */
  return (
    <>
      <Header
        title={
          <div className="flex items-center gap-6">
            <div className="flex flex-col ">
              <div>{t('welcome')}</div>
              {selectedPeriod?.isCurrentWeek && (
                <div className="detail-l text-white">
                  {t('last_updated_at', { time: lastUpdatedAt })}
                </div>
              )}
            </div>
            {isAllowed(Permission.READ_HUBS_ALL) && (
              <div className="w-52">
                <HubsMultiselect
                  values={selectedHubs}
                  onChange={setSelectedHubs}
                />
              </div>
            )}
          </div>
        }
      >
        <div className="flex gap-6">
          {isComplianceAllowed && (
            <Calendar
              list={periods}
              selectedIndex={selectedPeriodIndex}
              onChange={setSelectedPeriodIndex}
            />
          )}
          <div className="w-52">
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm('')}
              focusStyled
              icon="magnify"
              placeholder={t('search_riders')}
            />
          </div>
          {isAllowed(Permission.READ_COMPLIANCE_ALL) ||
            (isAllowed(Permission.READ_COMPLIANCE_ALLOWED) &&
              (isLatenessStrikesGenerationEnabled() ||
                isLateSickNoteStrikesGenerationEnabled()) && <Notifications />)}
        </div>
      </Header>
      <Outlet
        context={{
          selectedHubs,
          searchTerm: debouncedSearchTerm,
          setSearchTerm,
          selectedPeriod,
          setUpdatedAt,
        }}
      />
    </>
  );
};

const WorkforceIndex = waitPermissions(WorkforceIndexFC);
export { WorkforceIndex };
