import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonVariant, Icon } from '@hubportal/components';
import { ComplianceCaseStatus, SICKNESS_RATE_THRESHOLD } from 'utils/constants';
import { useState } from 'react';
import { track } from 'utils/tracking';
import { CloseCaseModal } from './close-case-modal';

export const CaseDecisionApproval = ({
  workforceID,
  ecID,
  sicknessRateCritical,
  openCases,
  onSuccess,
}: {
  workforceID: string;
  ecID: string;
  sicknessRateCritical: boolean;
  openCases: ComplianceCase[];
  onSuccess: (editedFields) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  return (
    <div className="px-4 py-6 bg-[rgba(35,39,42,0.50)] flex flex-col gap-6 rounded-lg">
      <div className="text-s flex flex-col gap-4">
        {info?.type === 'success' ? (
          <div className="flex gap-2">
            <div className="text-pink">
              <Icon type="check" />
            </div>
            <Trans i18nKey="compliance_case_termination_requested_confirmation_rejected" />
          </div>
        ) : (
          <>
            <Trans i18nKey="compliance_case_termination_requested_confirmation_info" />
            {sicknessRateCritical && (
              <div className="title-s text-red-100">
                {t('employee_sickness_rate_critical', {
                  threshold: SICKNESS_RATE_THRESHOLD,
                })}
              </div>
            )}
            <div>
              <Button
                variant={ButtonVariant.primary}
                onClick={() => {
                  track('click', {
                    component_content: 'reject',
                    component_name: 'termination_tracker',
                    screen_name: 'compliance',
                    component_value: `${ecID}`,
                  });
                  setConfirmationModal(true);
                }}
              >
                {t('reject')}
              </Button>
            </div>
          </>
        )}
        <CloseCaseModal
          open={confirmationModal}
          workforceID={workforceID}
          openCases={openCases}
          onSuccess={async () => {
            setInfo({ type: 'success', message: '' });
            onSuccess({ status: ComplianceCaseStatus.TERMINATION_REJECTED }); // TODO: check in case case status designs change
            return Promise.resolve();
          }}
          onClose={() => setConfirmationModal(false)}
        />
        {info?.type === 'error' && (
          <div className="text-pink text-xs">{t(info.message)}</div>
        )}
      </div>
    </div>
  );
};
