import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ButtonVariant, Checkbox } from '@hubportal/components';
import { Info } from 'components/info';
import { NoShow } from 'models/no-show';
import { format } from 'date-fns';

export const ExcuseNoShowsModal = ({
  list,
  open,
  onClose,
  onSubmit,
}: {
  list: NoShow[];
  open: boolean;
  onClose: () => void;
  onSubmit: (fields) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [noShows, setNoShows] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const excuseNoShows = async (ns?: string[]): Promise<void> => {
    try {
      setInfo(null);
      setLoading(true);
      await onSubmit(ns);
      setNoShows([]);
      onClose();
    } catch (err: any) {
      setInfo({ type: 'error', message: err?.message });
    } finally {
      setLoading(false);
    }
  };

  const toggleNoShow = (id: string): void => {
    const checked = noShows.includes(id);
    if (checked) {
      setNoShows(noShows.filter((nsId) => nsId !== id));
    } else {
      setNoShows([...noShows, id]);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setNoShows([]);
        onClose();
      }}
    >
      <div className="w-96 flex flex-col min-h-0">
        <div className="header-m text-left">
          {t('excuse_no_shows_modal_header')}:
        </div>
        <div className="flex flex-col gap-1 my-6 min-h-0 overflow-auto">
          {list.map((ns) => {
            const date = new Date(ns?.at || 0);

            const from = ns.schedules[0]?.from
              ? format(new Date(ns.schedules[0]?.from), 'HH:mm')
              : null;
            const to = ns.schedules[0]?.to
              ? format(new Date(ns.schedules[0]?.to), 'HH:mm')
              : null;

            return (
              <div
                key={ns.id}
                className="bg-secondary p-4 rounded cursor-pointer [&>div>label]:text-white [&>div>label]:title-m [&>div>label]:pointer-events-none"
                onClick={() => toggleNoShow(ns.id)}
              >
                <Checkbox
                  id={`ns-${ns.id}`}
                  checked={noShows.includes(ns.id)}
                  onChange={() => {}}
                >
                  <>
                    {`${format(date, 'dd.MM.yyyy')} ${
                      from && to ? `${from} - ${to}` : t('time_unknown')
                    }`}
                    {ns.warningDeliveredAt
                      ? ` (${t('warning_sent')})`
                      : ns.reminderDeliveredAt
                      ? ` (${t('reminder_sent')})`
                      : ''}
                  </>
                </Checkbox>
              </div>
            );
          })}
        </div>
        {info?.message && <Info type={info?.type}>{t(info?.message)}</Info>}
        <div className="flex gap-2 [&>button]:flex-1 [&>button]:justify-center">
          <Button variant={ButtonVariant.quinary} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={noShows.length === 0 || loading}
            loading={loading}
            onClick={() => {
              excuseNoShows(noShows);
            }}
          >
            {t('excuse_no_shows')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
