import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts';

const COLORS = {
  on_time: '#009B7D',
  late: '#F55B00',
  too_late: '#D8232A',
  excused: '#69AAE4',
  unexcused: '#BFBFBF',
};
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props: {
  cx: number;
  cy: number;
  midAngle: number;
  outerRadius: number;
  fill: string;
  value: number;
  percent: number;
  label: string;
}): JSX.Element => {
  const { cx, cy, midAngle, outerRadius, fill, value, percent, label } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 0) * cos;
  const sy = cy + (outerRadius + 0) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 10) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return percent > 0 ? (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="white"
        fontSize={percent > 0.05 ? '14px' : '11px'}
        className="lowercase"
      >{`${value} ${label}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={percent > 0.05 ? 15 : 10}
        textAnchor={textAnchor}
        fill="#BFBFBF"
        fontSize={percent > 0.05 ? '12px' : '9px'}
      >
        {`(Rate ${(percent * 100).toFixed(0)}%)`}
      </text>
    </g>
  ) : (
    <></>
  );
};

const CustomLabel = (props: {
  value1: string;
  value2: string;
  value3: string;
  viewBox?: {
    cx: number;
    cy: number;
  };
}): JSX.Element => {
  const { cx, cy } = props.viewBox || { cx: 0, cy: 0 };
  return (
    <>
      <text
        x={cx}
        y={cy - 12}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan alignmentBaseline="middle" fontSize="20px" fontWeight={700}>
          {props.value1}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 6}
        fill="#BFBFBF"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="14px" fontWeight={400}>
          {props.value2}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 24}
        fill="#BFBFBF"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="14px" fontWeight={400}>
          {props.value3}
        </tspan>
      </text>
    </>
  );
};

export const LateShifts = ({
  loaded,
  data,
  period,
}: {
  data: {
    total: number;
    onTime: number;
    late: number;
    tooLate: number;
    excused: number;
    unexcused: number;
  };
  loaded?: boolean;
  period: { from: Date; to: Date };
}): JSX.Element => {
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    return [
      { name: 'on_time', value: data.onTime, label: t('shifts_on_time') },
      { name: 'late', value: data.late, label: t('shifts_late', { n: 5 }) },
      {
        name: 'too_late',
        value: data.tooLate,
        label: t('shifts_late', { n: 10 }),
      },
      {
        name: 'excused',
        value: data.excused,
        label: t('excused_no_shows'),
      },
      {
        name: 'unexcused',
        value: data.unexcused,
        label: t('unexcused_no_shows'),
      },
    ];
  }, [data]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-4 items-center rounded-xl bg-primary text-gray-300 min-h-[14rem]">
        {/* {loaded && data?.total > 0 && (
          <div className="detail-l">
            {t('showing_data_from', {
              from: format(period.from || 0, 'MMM dd'),
              to: format(period.to || 0, 'MMM dd'),
            })}
          </div>
        )} */}
        {data.total > 0 ? (
          <ResponsiveContainer
            minWidth={500}
            minHeight={270}
            width="99%"
            height="99%"
          >
            <PieChart>
              <Pie
                data={chartData}
                labelLine={false}
                label={renderCustomizedLabel}
                innerRadius={85}
                outerRadius={100}
                dataKey="value"
                paddingAngle={2}
                minAngle={10}
                startAngle={45}
                endAngle={405}
              >
                {chartData.map((d, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[d.name]}
                    strokeOpacity="0"
                  />
                ))}
                <Label
                  key="custom-label"
                  width={50}
                  position="center"
                  content={
                    <CustomLabel
                      value1={`${data.total}`}
                      value2={t('completed')?.toLowerCase()}
                      value3={t('shifts')?.toLowerCase()}
                    />
                  }
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        ) : loaded ? (
          <div className="grow flex items-center text-s">
            {t('no_shifts_in_period', {
              from: format(period.from || 0, 'MMM dd'),
              to: format(period.to || 0, 'MMM dd'),
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
