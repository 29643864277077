import { useTranslation } from 'react-i18next';
import {
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Icon,
} from '@hubportal/components';
import { ComplianceCaseStatus, SICKNESS_RATE_THRESHOLD } from 'utils/constants';
import { useState } from 'react';
import { updateWorkforceComplianceCases } from 'utils/network/apis';
import { track } from 'utils/tracking';
import { openSAP } from 'utils/helpers';
import { CloseCaseModal } from 'partials/compliance-tracker/close-case-modal';

export const CaseDecision = ({
  workforceID,
  ecID,
  openCases,
  sicknessRateCritical,
  copyText,
  onSuccess,
  setSnackbar,
}: {
  workforceID: string;
  ecID: string;
  openCases: ComplianceCase[];
  sicknessRateCritical: boolean;
  copyText: () => string;
  onSuccess: (editedFields) => Promise<void>;
  setSnackbar: (snackbar: { visible: boolean; message: string }) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState('');

  const [confirmationModal, setConfirmationModal] = useState(false);

  const requestTermination = async (): Promise<void> => {
    try {
      setLoading(true);
      setError('');

      track('click', {
        component_content: 'to_sap',
        component_name: 'termination_tracker',
        screen_name: 'compliance',
        component_value: `${ecID}`,
      });

      await updateWorkforceComplianceCases(workforceID, {
        status: ComplianceCaseStatus.TERMINATION_REQUESTED,
      });

      openSAP(ecID);
      onSuccess({ status: ComplianceCaseStatus.TERMINATION_REQUESTED });
    } catch (err: any) {
      setError(err?.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };

  const dontTerminate = (): void => {
    track('click', {
      component_content: 'dont_terminate',
      component_name: 'termination_tracker',
      screen_name: 'compliance',
      component_value: `${ecID}`,
    });
    setConfirmationModal(true);
  };

  const copy = async (): Promise<void> => {
    track('click', {
      component_content: 'copy_case_information',
      component_name: 'termination_tracker',
      screen_name: 'compliance',
      component_value: `${ecID}`,
    });

    await navigator.clipboard.writeText(copyText());

    setCopied(true);
    setSnackbar({ visible: true, message: 'case_information_copied' });
    setTimeout(() => setSnackbar({ visible: false, message: '' }), 3000);
  };

  return (
    <div className="px-4 py-6 bg-[rgba(35,39,42,0.50)] flex flex-col gap-6 rounded-lg">
      <div className="header-s text-white text-center">
        {t('compliance_case_make_decision')}
      </div>
      <div className="flex gap-4">
        <div className="bg-primary p-6 w-[22rem] rounded-lg flex flex-col gap-2 shrink-0">
          <div className="title-m text-white">{t('do_not_terminate')}</div>
          <div className="text-s text-[#BFBFBF]">
            {t('compliance_case_make_decision_do_not_terminate_description')}
          </div>
          <div className="mt-6">
            <Button
              disabled={loading}
              loading={loading}
              variant={ButtonVariant.quinary}
              onClick={() => dontTerminate()}
            >
              {t('do_not_terminate')}
            </Button>
          </div>
        </div>
        <div className="bg-primary p-6 rounded-lg flex flex-col gap-2 grow">
          <div className="title-m text-white flex gap-2">
            {t('start_termination_in_sap')}
            {sicknessRateCritical && (
              <Badge variant={BadgeVariant['light-red']}>
                {t('recommended')}
              </Badge>
            )}
          </div>
          <div className="text-s text-[#BFBFBF]">
            {t('continue_in_sap_clipboard')}
          </div>
          {sicknessRateCritical && (
            <div className="title-s text-red-100">
              {t('employee_sickness_rate_critical', {
                threshold: SICKNESS_RATE_THRESHOLD,
              })}
            </div>
          )}
          {error && <div className="text-pink text-xs mt-auto">{t(error)}</div>}
          <div className="mt-auto flex gap-6 justify-between">
            <div
              className="flex gap-2 bg-secondary py-2 px-3 rounded title-m cursor-pointer text-white"
              onClick={() => {
                copy();
              }}
            >
              <Icon type="copy" />
              {t('copy_case_information')}
            </div>
            <Button
              disabled={!copied || loading}
              loading={loading}
              onClick={() => {
                requestTermination();
              }}
            >
              {t('continue_in_sap')}
            </Button>
          </div>
        </div>
      </div>

      <CloseCaseModal
        open={confirmationModal}
        workforceID={workforceID}
        openCases={openCases}
        onSuccess={onSuccess}
        setSnackbar={setSnackbar}
        onClose={() => setConfirmationModal(false)}
      />
    </div>
  );
};
