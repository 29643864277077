import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { CaseItemCircle } from '../components/case-item-circle';
import { WarningIcon } from '../components/warning-icon';
import { ReminderIcon } from '../components/reminder-icon';
import takeright from 'lodash.takeright';
import { Card } from './card';
import { ComplianceCaseStatus } from 'utils/constants';
import {
  Icon,
  Loading,
  Modal,
  ModalSize,
  ModalVariant,
} from '@hubportal/components';
import { getNoShowDays } from 'utils/network/apis';
import { NoShow } from 'models/no-show';
import { NoShowItem } from 'partials/no-shows/item';

type Props = {
  workforceID: string;
  complianceCase: ComplianceCase;
  copyText: () => string;
};

const NoShowsDetailsModal = ({
  workforceID,
  ids,
  copyText,
  onClose,
}: {
  workforceID: string;
  ids: string[];
  copyText: () => string;
  onClose: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const [noShows, setNoShows] = useState<NoShow[]>([]);
  const [loading, setLoading] = useState(false);

  const onLoad = async (): Promise<void> => {
    setLoading(true);
    const response = await getNoShowDays(workforceID, ids);
    setNoShows(response?.results?.map(NoShow.from).reverse() || []);
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, [ids]);

  const copy = async (): Promise<void> => {
    await navigator.clipboard.writeText(copyText());
  };

  return (
    <Modal
      open
      variant={ModalVariant.secondary}
      className="[&>div>div]:py-8 [&>div>div>div]:px-8"
      size={ModalSize.medium}
    >
      <div className="text-left flex flex-col gap-6 min-h-0">
        <div className="header-m flex gap-6 justify-between">
          <div className="capitalize">{t('unexcused_no_shows')}</div>
          <div
            className="bg-primary rounded-full cursor-pointer self-start p-2"
            onClick={onClose}
          >
            <Icon type="close" />
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex flex-col min-h-0 overflow-auto custom-horizontal-scroller">
              <div className="grid grid-cols-3 gap-x-6 gap-y-2 text-s">
                <div className="title-s">{t('dates')}</div>
                <div className="title-s">{t('shifts')}</div>

                <div className="title-s">{t('status')}</div>

                {(noShows || []).map((ns) => {
                  return (
                    <NoShowItem
                      key={ns.id}
                      item={ns}
                      isWarningsModuleEnabled
                      dark
                    />
                  );
                })}
              </div>
            </div>
            <div
              className="flex gap-2 bg-primary py-2 px-3 rounded title-m cursor-pointer text-white self-start"
              onClick={() => {
                copy();
              }}
            >
              <Icon type="copy" />
              {t('copy_case_information')}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export const NoShowCaseCard = ({
  workforceID,
  complianceCase,
  copyText,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const warningsThreshold = useMemo(() => {
    return Math.max(complianceCase.threshold?.warning || 0, 0);
  }, [complianceCase]);

  const remindersThreshold = useMemo(() => {
    return Math.max(complianceCase.threshold?.strike || 0, 0);
  }, [complianceCase]);

  const sendoutsAfterResolve = useMemo(() => {
    return (
      complianceCase.items?.filter((ns) => {
        return complianceCase?.resolved_at
          ? isAfter(
              new Date(ns.date || 0),
              new Date(complianceCase.resolved_at || 0)
            )
          : true;
      }) || []
    );
  }, [complianceCase]);

  const confirmedWarnings = useMemo(() => {
    return sendoutsAfterResolve.filter((ns) => {
      return (
        ns.status === 'UNEXCUSED' &&
        ns.sendouts?.filter((s) => s.type === 'warning').length > 0
      );
    });
  }, [sendoutsAfterResolve]);

  const warnings = useMemo(() => {
    const isThresholdMet = confirmedWarnings.length >= warningsThreshold;
    const confirmedAndPendingWarnings = sendoutsAfterResolve.filter((ns) => {
      return (
        (ns.status === 'UNEXCUSED' &&
          ns.sendouts?.filter((s) => s.type === 'warning').length > 0) ||
        (isThresholdMet ? false : ns.status === 'PENDING')
      );
    });

    return takeright(confirmedAndPendingWarnings, warningsThreshold);
  }, [complianceCase, warningsThreshold]);

  const reminders = useMemo(() => {
    const isThresholdMet = confirmedWarnings.length >= warningsThreshold;

    return isThresholdMet
      ? takeright(
          complianceCase.items
            ?.filter((ns) => {
              return complianceCase?.resolved_at
                ? isAfter(
                    new Date(ns.date || 0),
                    new Date(complianceCase.resolved_at || 0)
                  )
                : true;
            })
            .filter((ns) => {
              return (
                (ns.status === 'UNEXCUSED' &&
                  ns.sendouts?.filter((s) => s.type === 'warning').length ===
                    0) ||
                ns.status === 'PENDING'
              );
            }),
          remindersThreshold
        )
      : [];
  }, [complianceCase, remindersThreshold]);

  const pendingCount = useMemo(() => {
    return warnings.filter((ns: any) => ns?.status === 'PENDING').length;
  }, [warnings]);

  return (
    <Card
      type={
        complianceCase.status === ComplianceCaseStatus.OPENED
          ? 'alert'
          : 'default'
      }
      onClickDetails={() => setModalOpen(true)}
    >
      <div className="flex flex-col gap-6 h-full">
        <div className="flex flex-col">
          <div className="title-m text-white">
            {t('compliance_case_type_no_show')}
          </div>
          <div className="text-s text-[#BFBFBF]">
            {t('compliance_case_type_no_show_description')}
          </div>
        </div>
        <div className="mt-auto flex flex-col gap-2">
          <div className="flex gap-0.5 items-center">
            {Array.from(Array(warningsThreshold).keys()).map((i) => {
              const ns = warnings[i];
              return (
                <CaseItemCircle
                  key={i}
                  variant={
                    ns?.status === 'UNEXCUSED' || ns?.status === 'PENDING'
                      ? 'red'
                      : 'default'
                  }
                  opacity={ns?.status === 'PENDING'}
                  icon={<WarningIcon />}
                />
              );
            })}
            {Array.from(Array(remindersThreshold).keys()).map((i) => {
              const ns = reminders[i];
              return (
                <CaseItemCircle
                  key={i}
                  variant={
                    ns?.status === 'UNEXCUSED' || ns?.status === 'PENDING'
                      ? 'orange'
                      : 'default'
                  }
                  opacity={ns?.status === 'PENDING'}
                  icon={<ReminderIcon />}
                  last={i === remindersThreshold - 1}
                />
              );
            })}
          </div>
          <div className="text-s text-[#BFBFBF]">
            {warnings.length > 0 || pendingCount > 0
              ? [
                  warnings?.length > 0 &&
                    t('n_warnings', { count: warnings.length }),
                  pendingCount > 0 && t('n_pending', { count: pendingCount }),
                ]
                  .filter((s) => s)
                  .join(', ')
              : t('no_warnings')}
          </div>
        </div>
      </div>

      {modalOpen && (
        <NoShowsDetailsModal
          workforceID={workforceID}
          ids={complianceCase?.items?.map((ns) => ns.id) || []}
          copyText={copyText}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Card>
  );
};
