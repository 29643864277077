import { useTranslation } from 'react-i18next';
import { Badge, BadgeVariant, Icon } from '@hubportal/components';
import {
  CaseStatusToBadgeMap,
  ComplianceCaseStatus,
  ComplianceCaseType,
} from 'utils/constants';
import { format } from 'date-fns';
import { useRef } from 'react';
import { NoShowCaseCard } from './cards/no-show';
import { InactivityCard } from './cards/inactivity';
import { LatenessCard } from './cards/lateness';
import { CaseDecision } from './case-decision';
import { CaseDecisionApproval } from './case-decision-approval';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import {
  isLatenessStrikesGenerationEnabled,
  isLateSickNoteStrikesGenerationEnabled,
} from 'utils/eppo/helpers';

type TerminationDecisionProps = {
  status: ComplianceCaseStatus;
  workforceID: string;
  ecID: string;
  cases: ComplianceCase[];
  sicknessRateCritical: boolean;
  copyText: () => string;
  onSuccess: (editedFields) => Promise<void>;
  setSnackbar: (snackbar: { visible: boolean; message: string }) => void;
};

const TerminationDecision = ({
  status,
  workforceID,
  ecID,
  cases,
  sicknessRateCritical,
  copyText,
  onSuccess,
  setSnackbar,
}: TerminationDecisionProps): JSX.Element => {
  const { isAllowed } = usePermissions();

  if (
    status === ComplianceCaseStatus.OPENED &&
    (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
      isAllowed(Permission.READ_COMPLIANCE_ALLOWED)) &&
    !isAllowed(Permission.WRITE_COMPLIANCE_CONFIRMATION_ALL)
  ) {
    return (
      <CaseDecision
        workforceID={workforceID}
        ecID={ecID}
        openCases={cases?.filter(
          (c) => c.status === ComplianceCaseStatus.OPENED
        )}
        sicknessRateCritical={sicknessRateCritical}
        copyText={copyText}
        onSuccess={onSuccess}
        setSnackbar={setSnackbar}
      />
    );
  }
  if (
    (status === ComplianceCaseStatus.TERMINATION_REQUESTED ||
      status === ComplianceCaseStatus.TERMINATION_REJECTED) &&
    isAllowed(Permission.WRITE_COMPLIANCE_CONFIRMATION_ALL)
  ) {
    return (
      <CaseDecisionApproval
        workforceID={workforceID}
        ecID={ecID}
        sicknessRateCritical={sicknessRateCritical}
        openCases={cases?.filter(
          (c) => c.status === ComplianceCaseStatus.TERMINATION_REQUESTED
        )}
        onSuccess={onSuccess}
      />
    );
  }

  return <></>;
};

export const ComplianceTracker = ({
  tracker,
  cases,
  ecID,
  terminatedAt,
  workforceID,
  sicknessRateCritical,
  getCaseCopy,
  onSuccess,
  setSnackbar,
}: {
  tracker: ComplianceTracker;
  cases: ComplianceCase[];
  ecID: string;
  terminatedAt?: string;
  workforceID: string;
  sicknessRateCritical: boolean;
  getCaseCopy: () => string;
  onSuccess: (editedFields) => Promise<void>;
  setSnackbar: (snackbar: { visible: boolean; message: string }) => void;
}): JSX.Element => {
  const scrollerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const { isAllowed } = usePermissions();

  const isLatenessStrikesEnabled = isLatenessStrikesGenerationEnabled();
  const isLateSickNoteStrikesEnabled = isLateSickNoteStrikesGenerationEnabled();

  const prev = (): void => {
    scrollerRef?.current?.scrollTo({
      left: 0,
      behavior: 'smooth',
    });
  };

  const next = (): void => {
    scrollerRef?.current?.scrollTo({
      left: scrollerRef?.current?.offsetWidth,
      behavior: 'smooth',
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-4">
        <div className="header-s text-white">{t('compliance_tracker')}</div>
        {[
          ComplianceCaseStatus.TERMINATION_REQUESTED,
          ComplianceCaseStatus.TERMINATION_APPROVED,
          ComplianceCaseStatus.OPENED,
        ].includes(tracker.status) ? (
          <Badge variant={CaseStatusToBadgeMap[tracker.status].variant}>
            {t(CaseStatusToBadgeMap[tracker.status].label)}
          </Badge>
        ) : tracker.status === ComplianceCaseStatus.TERMINATED ? (
          <></>
        ) : (
          <Badge variant={BadgeVariant.light}>
            {t('not_eligible_for_termination')}
          </Badge>
        )}
      </div>
      {tracker.status === ComplianceCaseStatus.TERMINATION_APPROVED && (
        <div className="p-8 bg-primary rounded-lg text-s mt-4">
          {t('termination_tracker_termination_approved_description', {
            date: format(new Date(terminatedAt || 0), 'dd.MM.yyyy'),
          })}
        </div>
      )}
      {tracker.status === ComplianceCaseStatus.TERMINATION_REQUESTED &&
        !isAllowed(Permission.WRITE_COMPLIANCE_CONFIRMATION_ALL) && (
          <div className="p-8 bg-primary rounded-lg text-s mt-4">
            {t('termination_tracker_termination_requested_description')}
          </div>
        )}
      {tracker.status !== ComplianceCaseStatus.TERMINATION_APPROVED &&
        tracker.status !== ComplianceCaseStatus.TERMINATION_REQUESTED && (
          <>
            <div className="flex gap-10">
              <div className="text-m text-white max-w-2xl">
                {t('compliance_tracker_description')}
              </div>
              <div className="flex gap-4 items-end ml-auto">
                <div
                  className={`rounded-full bg-primary p-2 cursor-pointer text-white`}
                  onClick={prev}
                >
                  <Icon type="arrowSignLeft" />
                </div>
                <div
                  className={`rounded-full bg-primary p-2 cursor-pointer text-white`}
                  onClick={next}
                >
                  <Icon type="arrowSignRight" />
                </div>
              </div>
            </div>
            <div
              ref={scrollerRef}
              className="overflow-x-auto min-w-0 custom-horizontal-scroller py-3 mt-6 flex gap-6"
            >
              {cases?.map((complianceCase, i) => {
                return (
                  <div key={i}>
                    {complianceCase.type === ComplianceCaseType.NO_SHOW && (
                      <NoShowCaseCard
                        workforceID={workforceID}
                        complianceCase={complianceCase}
                        copyText={getCaseCopy}
                      />
                    )}

                    {complianceCase.type === ComplianceCaseType.INACTIVITY && (
                      <InactivityCard complianceCase={complianceCase} />
                    )}

                    {isLatenessStrikesEnabled &&
                      complianceCase.type ===
                        ComplianceCaseType.SHORT_LATENESS && (
                        <LatenessCard
                          title={t('compliance_case_type_short_lateness')}
                          description={t(
                            'compliance_case_type_short_lateness_description'
                          )}
                          complianceCase={complianceCase}
                          copyText={getCaseCopy}
                        />
                      )}

                    {isLatenessStrikesEnabled &&
                      complianceCase.type ===
                        ComplianceCaseType.LONG_LATENESS && (
                        <LatenessCard
                          title={t('compliance_case_type_long_lateness')}
                          description={t(
                            'compliance_case_type_long_lateness_description'
                          )}
                          complianceCase={complianceCase}
                          copyText={getCaseCopy}
                        />
                      )}

                    {isLateSickNoteStrikesEnabled &&
                      complianceCase.type ===
                        ComplianceCaseType.LATE_SICK_NOTE && (
                        <LatenessCard
                          title={t('compliance_case_type_late_sick_note')}
                          description={t(
                            'compliance_case_type_late_sick_note_description'
                          )}
                          complianceCase={complianceCase}
                          copyText={getCaseCopy}
                        />
                      )}
                  </div>
                );
              })}
            </div>
          </>
        )}

      <div>
        <TerminationDecision
          status={tracker.status}
          workforceID={workforceID}
          ecID={ecID}
          cases={cases}
          sicknessRateCritical={sicknessRateCritical}
          copyText={getCaseCopy}
          onSuccess={onSuccess}
          setSnackbar={setSnackbar}
        />
      </div>
    </div>
  );
};
