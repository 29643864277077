import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isAfter, isBefore, subDays } from 'date-fns';
import { CaseItemCircle } from '../components/case-item-circle';
import { WarningIcon } from '../components/warning-icon';
import { ReminderIcon } from '../components/reminder-icon';
import takeright from 'lodash.takeright';
import { Card } from './card';
import { ComplianceCaseStatus, ComplianceCaseType } from 'utils/constants';
import { getStrikes } from 'utils/network/apis';
import { StrikeItem } from 'partials/strike';
import {
  Icon,
  Loading,
  Modal,
  ModalSize,
  ModalVariant,
} from '@hubportal/components';

type Props = {
  complianceCase: ComplianceCase;
  title?: string;
  description?: string;
  copyText: () => string;
};

const StrikesModal = ({
  type,
  caseID,
  caseType,
  copyText,
  onClose,
}: {
  type: string;
  caseID: string;
  caseType: ComplianceCaseType;
  copyText: () => string;
  onClose: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const [strikes, setStrikes] = useState<Strike[]>([]);
  const [loading, setLoading] = useState(false);

  const onLoad = async (): Promise<void> => {
    setLoading(true);
    const response = await getStrikes({
      statuses: ['CONFIRMED', 'PENDING'],
      to_date: format(subDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      case_id: caseID,
      limit: 999,
    });
    setStrikes(response?.results || []);
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, [caseID]);

  const confirmed = useMemo(() => {
    return strikes.filter((strike) => strike.status === 'CONFIRMED');
  }, [strikes]);

  const pending = useMemo(() => {
    return strikes.filter((strike) => strike.status === 'PENDING');
  }, [strikes]);

  const copy = async (): Promise<void> => {
    await navigator.clipboard.writeText(copyText());
  };

  return (
    <Modal
      open
      variant={ModalVariant.secondary}
      className="[&>div>div]:py-8 [&>div>div>div]:px-8"
      size={ModalSize.medium}
    >
      <div className="text-left flex flex-col gap-6 min-h-0">
        <div className="header-m flex gap-6 justify-between">
          <div className="capitalize">
            {t(`strike_type_${type.toLowerCase()}`)}
          </div>
          <div
            className="bg-primary rounded-full cursor-pointer self-start p-2"
            onClick={onClose}
          >
            <Icon type="close" />
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex flex-col min-h-0 overflow-auto custom-horizontal-scroller">
              <div className="flex flex-col gap-1 min-h-0 overflow-auto custom-horizontal-scroller">
                {pending?.length > 0 && (
                  <div className="title-s text-[#BFBFBF] pb-2">
                    {t('pending')}
                  </div>
                )}
                {pending?.map((strike) => {
                  return <StrikeItem key={strike.id} strike={strike} />;
                })}
                {pending?.length > 0 && (
                  <div className="title-s text-[#BFBFBF] pb-2 mt-10">
                    {t('confirmed')}
                  </div>
                )}
                {confirmed?.map((strike) => {
                  return <StrikeItem key={strike.id} strike={strike} />;
                })}
                {confirmed?.length === 0 && pending?.length === 0 && (
                  <div className="text-s text-[#BFBFBF]">
                    {[
                      ComplianceCaseType.SHORT_LATENESS,
                      ComplianceCaseType.LONG_LATENESS,
                    ].includes(caseType)
                      ? t('no_late_shifts')
                      : t('no_records')}
                  </div>
                )}
              </div>
            </div>
            {(pending?.length > 0 || confirmed?.length > 0) && (
              <div
                className="flex gap-2 bg-primary py-2 px-3 rounded title-m cursor-pointer text-white self-start"
                onClick={() => {
                  copy();
                }}
              >
                <Icon type="copy" />
                {t('copy_case_information')}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export const LatenessCard = ({
  title,
  description,
  complianceCase,
  copyText,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const warningsThreshold = useMemo(() => {
    return Math.max(complianceCase.threshold?.warning || 0, 0);
  }, [complianceCase]);

  const remindersThreshold = useMemo(() => {
    return Math.max(complianceCase.threshold?.strike || 0, 0);
  }, [complianceCase]);

  const strikesAfterResolve = useMemo(() => {
    return (
      complianceCase.items
        ?.filter((item) => {
          return isBefore(new Date(item.date || 0), subDays(new Date(), 1));
        })
        .filter((item) => {
          return complianceCase?.resolved_at
            ? isAfter(
                new Date(item.date || 0),
                new Date(complianceCase.resolved_at || 0)
              )
            : true;
        }) || []
    );
  }, [complianceCase]);

  const confirmedWarnings = useMemo(() => {
    return strikesAfterResolve.filter((strike) => {
      return (
        strike.status === 'CONFIRMED' &&
        strike.sendouts?.filter((s) => s.type === 'warning').length > 0
      );
    });
  }, [strikesAfterResolve]);

  const warnings = useMemo(() => {
    const isThresholdMet = confirmedWarnings.length >= warningsThreshold;
    const confirmedAndPendingWarnings = strikesAfterResolve.filter((strike) => {
      return (
        (strike.status === 'CONFIRMED' &&
          strike.sendouts?.filter((s) => s.type === 'warning').length > 0) ||
        (isThresholdMet ? false : strike.status === 'PENDING')
      );
    });

    return takeright(confirmedAndPendingWarnings, warningsThreshold);
  }, [strikesAfterResolve, warningsThreshold]);

  const reminders = useMemo(() => {
    const isThresholdMet = confirmedWarnings.length >= warningsThreshold;

    return isThresholdMet
      ? takeright(
          strikesAfterResolve.filter((strike) => {
            return (
              (strike.status === 'CONFIRMED' &&
                strike.sendouts?.filter((s) => s.type === 'warning').length ===
                  0) ||
              strike.status === 'PENDING'
            );
          }),
          remindersThreshold
        )
      : [];
  }, [complianceCase, remindersThreshold]);

  const pendingCount = useMemo(() => {
    return [...warnings, ...reminders].filter((s) => s?.status === 'PENDING')
      .length;
  }, [warnings]);

  return (
    <Card
      type={
        complianceCase.status === ComplianceCaseStatus.OPENED
          ? 'alert'
          : 'default'
      }
      badge={
        complianceCase.status !== ComplianceCaseStatus.OPENED &&
        pendingCount > 0
          ? t('action_needed')
          : ''
      }
      onClickDetails={() => setModalOpen(true)}
    >
      <div className="flex flex-col gap-6 h-full">
        <div className="flex flex-col">
          <div className="title-m text-white">{title}</div>
          <div className="text-s text-[#BFBFBF]">{description}</div>
        </div>
        <div className="mt-auto flex flex-col gap-2">
          <div className="flex gap-0.5 items-center">
            {Array.from(Array(warningsThreshold).keys()).map((i) => {
              const s = warnings[i];
              return (
                <CaseItemCircle
                  key={i}
                  variant={
                    s?.status === 'CONFIRMED' || s?.status === 'PENDING'
                      ? 'red'
                      : 'default'
                  }
                  opacity={s?.status === 'PENDING'}
                  icon={<WarningIcon />}
                />
              );
            })}
            {Array.from(Array(remindersThreshold).keys()).map((i) => {
              const s = reminders[i];
              return (
                <CaseItemCircle
                  key={i}
                  variant={
                    s?.status === 'CONFIRMED' || s?.status === 'PENDING'
                      ? 'orange'
                      : 'default'
                  }
                  opacity={s?.status === 'PENDING'}
                  icon={<ReminderIcon />}
                  last={i === remindersThreshold - 1}
                />
              );
            })}
          </div>
          <div className="text-s text-[#BFBFBF]">
            {warnings.length > 0 || pendingCount > 0
              ? [
                  warnings.length - pendingCount > 0 &&
                    t('n_warnings', { count: warnings.length - pendingCount }),
                  pendingCount > 0 && t('n_pending', { count: pendingCount }),
                ]
                  .filter((s) => s)
                  .join(', ')
              : t('no_warnings')}
          </div>
        </div>
      </div>

      {modalOpen && (
        <StrikesModal
          type={complianceCase.type}
          caseID={complianceCase.id}
          caseType={complianceCase.type}
          copyText={copyText}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Card>
  );
};
