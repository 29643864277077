import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Button,
  Input,
  InputVariant,
  SelectVariant,
  Snackbar,
  SnackbarVariant,
} from '@hubportal/components';
import { PHONE_NUMBER_REGEX } from 'utils/helpers';
import { Employee } from 'models/employee';
import { AgencySelect, HubsSelect } from 'partials/select';
import { RiderStates } from 'utils/constants';
import { isPunchOnlineEnabled } from 'utils/eppo/helpers';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import omit from 'lodash.omit';
import * as Yup from 'yup';

const PersonalData = ({
  employee,
}: {
  employee: Employee | null;
}): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();

  const [snackbar, setSnackbar] = useState<{
    type: SnackbarVariant;
    message: string;
  } | null>(null);

  const isHubChangeEnabled = useMemo(
    () => employee?.workforceID && (!employee?.ecID || !isPunchOnlineEnabled()),
    [employee]
  );

  const submit = async ({ email, phone, hub, agency }): Promise<void> => {
    try {
      setSnackbar(null);

      if (employee?.email !== email) {
        await employee?.changeEmail(email);
      }
      if (employee?.phoneNumber !== phone) {
        await employee?.changePhoneNumber(phone);
      }
      if (employee?.hubSlug !== hub) {
        await employee?.changeHub(hub);
      }
      if (employee?.externalAgency && employee?.externalAgency !== agency) {
        await employee?.changeAgency(agency);
      }

      setSnackbar({
        type: SnackbarVariant.success,
        message: 'personal_data_updated',
      });

      setTimeout(() => {
        setSnackbar(null);
      }, 2000);
    } catch (err: any) {
      setSnackbar({
        type: SnackbarVariant.error,
        message: err?.response?.data?.error || err?.message,
      });
      throw err;
    }
  };

  const validationSchema = useMemo(() => {
    return Yup.object({
      email: Yup.string().email(t('invalid_email')).required(t('required')),
      phone: Yup.string()
        .matches(RegExp(PHONE_NUMBER_REGEX), t('invalid_phone_number'))
        .required(t('required')),
      hub: Yup.object({
        id: Yup.string().required(t('required')),
        label: Yup.string(),
      }).required(),
    });
  }, [t]);

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      hub: { id: '', label: '' },
      agency: { id: '', label: '' },
    },
    validationSchema,
    onSubmit: async () => {
      formik.setSubmitting(true);

      await submit({
        email: formik.values.email,
        phone: formik.values.phone,
        hub: formik.values.hub.id?.toLowerCase(),
        agency: formik.values.agency?.id?.toLowerCase(),
      });

      formik.resetForm({
        values: {
          email: employee?.email || '',
          phone: employee?.phoneNumber || '',
          hub: {
            id: employee?.hubSlug || '',
            label: employee?.hubSlug || '',
          },
          agency: {
            id: employee?.externalAgency || '',
            label: employee?.externalAgency || '',
          },
        },
      });

      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        email: employee?.email || '',
        phone: employee?.phoneNumber || '',
        hub: { id: employee?.hubSlug || '', label: employee?.hubSlug || '' },
        agency: {
          id: employee?.externalAgency || '',
          label: employee?.externalAgency || '',
        },
      },
    });
  }, [employee]);

  return (
    <div className="w-3/6 max-w-sm">
      <div className="text-white header-s mb-6">{t('personal_data')}</div>
      <div className="bg-primary rounded-lg p-4">
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className="flex flex-col items-start gap-4"
        >
          <Input
            type="email"
            required
            label={t('email')}
            placeholder=" "
            variant={InputVariant.secondary}
            disabled={!isAllowed(Permission.WRITE_EMAIL_ALL)}
            error={formik.touched.email ? (formik.errors.email as string) : ''}
            {...formik.getFieldProps('email')}
          />

          <Input
            type="tel"
            required
            label={t('phone_number')}
            placeholder=" "
            variant={InputVariant.secondary}
            disabled={!isAllowed(Permission.WRITE_PHONENUMBER_ALL)}
            error={formik.touched.phone ? (formik.errors.phone as string) : ''}
            {...formik.getFieldProps('phone')}
          />

          {employee?.homeHub && (
            <Input
              disabled
              label={t('home_hub')}
              placeholder=" "
              variant={InputVariant.secondary}
              value={employee?.homeHub || ''}
            />
          )}

          <div className="w-full">
            <HubsSelect
              variant={SelectVariant.secondary}
              error={formik.touched?.hub ? formik.errors?.hub?.id : ''}
              disabled={
                !isHubChangeEnabled || employee?.status === RiderStates.BUSY
              }
              onChange={(value) =>
                formik.handleChange({ target: { name: 'hub', value } })
              }
              {...omit(formik.getFieldProps('hub'), 'onChange')}
            />
          </div>

          {employee?.externalAgency && (
            <div className="w-full">
              <AgencySelect
                variant={SelectVariant.secondary}
                error={formik.touched?.agency ? formik.errors?.agency?.id : ''}
                disabled={!isAllowed(Permission.WRITE_RIDERPROFILE_ALL)}
                onChange={(value) =>
                  formik.handleChange({
                    target: { name: 'agency', value },
                  })
                }
                {...omit(formik.getFieldProps('agency'), 'onChange')}
              />
            </div>
          )}

          <Button
            type="submit"
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            loading={formik.isSubmitting}
          >
            {t('save')}
          </Button>
        </form>

        <div className="[&>div]:z-10">
          <Snackbar
            open={!!snackbar?.type}
            variant={snackbar?.type as SnackbarVariant}
            icon={
              snackbar?.type === SnackbarVariant.success
                ? 'verification'
                : 'warning'
            }
            onClose={() => setSnackbar(null)}
          >
            {t(snackbar?.message || '')}
          </Snackbar>
        </div>
      </div>
    </div>
  );
};

export { PersonalData };
