import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Snackbar, SnackbarVariant } from '@hubportal/components';

const ProfileItem = ({
  label,
  value,
  copyable,
  direction = 'row',
}: {
  label: string;
  value: string | null | undefined | JSX.Element;
  copyable?: boolean;
  direction?: 'row' | 'col';
}): JSX.Element => {
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState(false);

  const copy = async (text: string): Promise<void> => {
    await navigator.clipboard.writeText(text);
    setSnackbar(true);
    setTimeout(() => setSnackbar(false), 3000);
  };

  return (
    <div
      className={`flex gap-2 title-s ${
        direction === 'col' ? 'flex-col min-w-[2.5rem]' : 'items-center'
      }`}
    >
      {label && <span>{`${label}${direction === 'row' ? ': ' : ''}`}</span>}
      <div
        className={`flex gap-2 items-center ${
          copyable ? 'bg-primary bg-opacity-50 p-2 rounded' : 'text-white'
        }`}
      >
        {value || '-'}
        {copyable && value && (
          <Icon
            type="copy"
            onClick={() => {
              copy(value as string);
            }}
          />
        )}
      </div>

      <div className="[&>div]:z-10">
        <Snackbar
          open={snackbar}
          variant={SnackbarVariant.success}
          icon="verification"
          onClose={() => setSnackbar(false)}
        >
          {t('copied_to_clipboard')}
        </Snackbar>
      </div>
    </div>
  );
};

export { ProfileItem };
