import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonVariant,
  Icon,
  Modal,
  ModalVariant,
  Textarea,
  TextareaVariant,
} from '@hubportal/components';
import { ComplianceCaseStatus } from 'utils/constants';
import { useState } from 'react';
import { updateWorkforceComplianceCases } from 'utils/network/apis';

type CloseCaseModalProps = {
  open: boolean;
  workforceID: string;
  openCases: ComplianceCase[];
  onClose: () => void;
  onSuccess: (editedFields) => Promise<void>;
  setSnackbar?: (props) => void;
};

export const CloseCaseModal = ({
  open,
  workforceID,
  openCases,
  onClose,
  onSuccess,
  setSnackbar = (props) => {},
}: CloseCaseModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const onConfirm = async (): Promise<void> => {
    try {
      setError('');
      setLoading(true);
      await updateWorkforceComplianceCases(workforceID, {
        status: ComplianceCaseStatus.CLOSED,
        description: value,
      });

      if (setSnackbar) {
        setSnackbar({
          visible: true,
          message: 'compliance_cases_close_success_message',
        });
        setTimeout(() => setSnackbar({ visible: false, message: '' }), 3000);
      }

      onSuccess({ status: ComplianceCaseStatus.CLOSED });
      onClose();
    } catch (err: any) {
      setError(err?.response?.data?.error || err.message);
    } finally {
      setValue('');
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      variant={ModalVariant.secondary}
      className="[&>div>div]:py-8 [&>div>div>div]:px-8"
    >
      <>
        <div className="text-left max-w-lg flex flex-col gap-6">
          <div className="header-m flex gap-6 justify-between">
            <div>{t('compliance_case_close_modal_header')}</div>
            <div
              className="bg-primary rounded-full cursor-pointer self-start p-2"
              onClick={onClose}
            >
              <Icon type="close" />
            </div>
          </div>
          <div className="[&>textarea]:!w-full">
            <Textarea
              value={value}
              rows={4}
              variant={TextareaVariant.primary}
              placeholder={t('specify_reasons')}
              onChange={(e) => setValue(e?.target?.value)}
            />
          </div>
          {error && <span className="text-xs text-pink">{error}</span>}
          <div className="flex flex-col gap-6 text-left text-m text-white">
            <div>{t('compliance_case_close_modal_warning')}</div>
            <ul className="list-disc list-inside title-m">
              {openCases?.map((complianceCase, i) => {
                return (
                  <li key={i}>
                    {t(
                      `compliance_case_type_${complianceCase.type?.toLowerCase()}`
                    )}
                  </li>
                );
              })}
            </ul>
            <div>{t('can_not_be_undone')}</div>
          </div>
        </div>
        <div className="flex gap-2 mt-6 [&>button]:flex-1 [&>button]:justify-center">
          <Button variant={ButtonVariant.quaternary} onClick={onClose}>
            {t('cancel')}
          </Button>
          <div className="[&>button]:bg-[#D8232A] [&>button]:w-full [&>button]:justify-center flex-1">
            <Button
              variant={ButtonVariant.primary}
              disabled={loading || !value}
              onClick={() => {
                onConfirm();
              }}
            >
              {t('do_not_terminate')}
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
