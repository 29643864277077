import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { NoShow } from 'models/no-show';
import { Icon, Tooltip, TooltipVariant } from '@hubportal/components';

export const NoShowItem = ({
  isWarningsModuleEnabled,
  item: noShow,
  dark,
}: {
  isWarningsModuleEnabled?: boolean;
  item: NoShow;
  dark?: boolean;
}): JSX.Element => {
  const ref = useRef(null);

  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // if (!noShow.schedules?.length) {
  //   return <span key={noShow?.id}></span>;
  // }

  const date = new Date(noShow?.at || 0);

  return (
    <span className="contents">
      <div
        className={`col-span-3 border-b border-solid ${
          dark ? 'border-primary' : 'border-secondary'
        }`}
      ></div>
      <div>{format(date, 'dd.MM.yyyy')}</div>
      <div className="flex flex-col gap-3">
        {noShow?.schedules?.map((s, idx) => {
          const from = s.from ? format(new Date(s.from), 'HH:mm') : null;
          const to = s.to ? format(new Date(s.to), 'HH:mm') : null;
          return (
            <div key={idx}>
              {from && to ? `${from}-${to}` : t('time_unknown')}
            </div>
          );
        })}
      </div>

      <div>
        {isWarningsModuleEnabled &&
          (noShow.warningDeliveredAt ? (
            <div className="flex gap-2 items-center">
              {t('warning_sent')}
              <div
                ref={ref}
                onMouseOver={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
              >
                <Icon type="warningFilled" size="small" />
              </div>
              <Tooltip
                refElement={ref?.current || null}
                visible={tooltipVisible}
                variant={TooltipVariant.pink}
                placement="top"
              >
                <div className="max-w-[200px]">
                  {format(
                    new Date(noShow?.warningDeliveredAt || 0),
                    'dd.MM.yyyy'
                  )}
                </div>
              </Tooltip>
            </div>
          ) : noShow.reminderDeliveredAt ? (
            t('reminder_sent')
          ) : (
            ' '
          ))}
      </div>
    </span>
  );
};
