export const ReminderIcon = (): JSX.Element => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.68187 2.81836L3.31787 9.18236L9.68187 2.81836Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 1.5V1.5C4.0145 1.5 2 3.5145 2 6V6C2 8.4855 4.0145 10.5 6.5 10.5V10.5C8.9855 10.5 11 8.4855 11 6V6C11 3.5145 8.9855 1.5 6.5 1.5Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
