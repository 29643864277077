export const CaseItemCircle = ({
  variant,
  opacity,
  icon,
  last,
}: {
  variant: 'red' | 'orange' | 'default';
  opacity?: boolean;
  icon: JSX.Element;
  last?: boolean;
}): JSX.Element => {
  return (
    <>
      <div
        className={`flex items-center justify-center w-5 h-5 p-1 rounded-full ${
          variant === 'red' ? '' : ''
        } ${
          variant === 'red'
            ? 'bg-[#D8232A] text-white'
            : variant === 'orange'
            ? 'bg-[#F55B00] text-white'
            : 'bg-[#3A4045] text-[#BFBFBF]'
        } ${opacity ? 'opacity-50' : ''}`}
      >
        {icon}
      </div>
      {!last && <div className="w-2 h-0 border border-[#BFBFBF]" />}
    </>
  );
};
