import {
  Button,
  Icon,
  Modal,
  ModalSize,
  ModalVariant,
} from '@hubportal/components';
import { Sidebar } from 'components/sidebar-temp';
import { format, subDays } from 'date-fns';
import { StrikeItem } from 'partials/strike';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMountedState from 'utils/hooks/useMountedState';
import usePolling from 'utils/hooks/usePolling';
import { getStrikes } from 'utils/network/apis';
import useStore from 'utils/store';

const StrikesModal = ({
  strikes,
  open,
  onClose,
}: {
  strikes: Strike[];
  open: boolean;
  onClose: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      variant={ModalVariant.secondary}
      className="[&>div>div]:py-8 [&>div>div>div]:px-8"
      size={ModalSize.medium}
    >
      <div className="text-left flex flex-col gap-6 min-h-0">
        <div className="header-m flex gap-6 justify-between">
          <div>{t('compliance_cases_review')}</div>
          <div
            className="bg-primary rounded-full cursor-pointer self-start p-2"
            onClick={onClose}
          >
            <Icon type="close" />
          </div>
        </div>
        <div className="flex flex-col gap-1 min-h-0 overflow-auto custom-horizontal-scroller">
          {strikes?.map((strike) => {
            return <StrikeItem key={strike.id} strike={strike} displayBadge />;
          })}
        </div>
      </div>
    </Modal>
  );
};

export const Notifications = (): JSX.Element => {
  const isMounted = useMountedState();
  const { t } = useTranslation();
  const { selectedHub: hub } = useStore();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [result, setResult] = useState<{
    pagination: { total: number; next: string | null };
    strikes: Strike[];
  }>({ pagination: { total: 0, next: null }, strikes: [] });

  const strikes = async (): Promise<void> => {
    if (!hub) {
      return;
    }
    const response = await getStrikes({
      statuses: 'PENDING',
      hubs: hub.slug,
      to_date: format(subDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      limit: 999,
    });

    if (isMounted()) {
      setResult({
        pagination: response.pagination || { total: 0, next: null },
        strikes: response.results || [],
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, respawn } = usePolling(async () => await strikes(), [hub], {
    interval: 30000,
  });

  const onCloseModal = async (): Promise<void> => {
    await strikes();
    setModalOpen(false);
  };

  return (
    <div className="relative">
      <div
        onClick={() => setSidebarOpen(true)}
        className="p-1.5 rounded-lg bg-primary h-full aspect-square flex items-center justify-center cursor-pointer"
      >
        <Icon type="notification" size="large" />
      </div>

      {result?.strikes?.length > 0 && (
        <div className="absolute w-4 h-4 rounded-full bg-pink -top-1 -right-1" />
      )}

      <Sidebar
        header={<div className="header-m text-white">{t('notifications')}</div>}
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        className="!px-4 !py-10 [&>div]:!px-0"
      >
        <div className="rounded-lg bg-secondary p-4 flex flex-col gap-4">
          <div className="bg-[#FCE7F1] text-[#E31C79] p-1.5 rounded-full self-start">
            <Icon type="lightBulb" />
          </div>
          <div className="flex flex-col gap-2 text-white">
            <div className="title-m">{t('catch_up_on_compliance_cases')}</div>
            <div className="text-s">
              {t('catch_up_on_compliance_cases_subtitle')}
            </div>
          </div>
          {result?.strikes?.length > 0 && (
            <div>
              <Button onClick={() => setModalOpen(true)}>
                {t('review_cases')}
              </Button>
            </div>
          )}
        </div>
      </Sidebar>
      <StrikesModal
        strikes={result?.strikes}
        open={modalOpen}
        onClose={() => {
          onCloseModal();
        }}
      />
    </div>
  );
};
