import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays, format } from 'date-fns';
import { ComplianceCaseStatus } from 'utils/constants';
import { Card } from './card';

type Props = {
  complianceCase: ComplianceCase;
};

export const InactivityCard = ({ complianceCase }: Props): JSX.Element => {
  const { t } = useTranslation();

  const lastActivityAt = useMemo(() => {
    return complianceCase.extra?.last_activity_at || null;
  }, [complianceCase]);

  const inactivityDays = useMemo(() => {
    return complianceCase.extra?.last_activity_at
      ? differenceInDays(
          new Date(),
          new Date(complianceCase.extra?.last_activity_at || 0)
        )
      : null;
  }, [complianceCase]);

  const threshold = useMemo(() => {
    return complianceCase.extra?.limit || 0;
  }, [complianceCase]);

  return (
    <Card
      type={
        complianceCase.status === ComplianceCaseStatus.OPENED
          ? 'alert'
          : 'default'
      }
      //   onClickDetails={() => {}}
    >
      <div className="flex flex-col gap-6 h-full">
        <div className="flex flex-col">
          <div className="title-m text-white">
            {t('compliance_case_type_inactivity')}
          </div>
          <div className="text-s text-[#BFBFBF]">
            {t('compliance_case_type_inactivity_description')}
          </div>
        </div>
        <div className="mt-auto flex flex-col">
          <div className="w-full h-1 bg-[#3A4045] relative rounded-[0.25rem]">
            <div
              style={{
                width: `${
                  inactivityDays
                    ? Math.min(
                        ((inactivityDays / (threshold || 1)) * 100).toFixed(
                          2
                        ) as any,
                        100
                      )
                    : 0
                }%`,
              }}
              className="h-full bg-[#D8232A] rounded-[0.25rem]"
            />
          </div>
          <div className="title-m text-white pt-0.5 pb-2 lowercase">
            {inactivityDays ? `${inactivityDays} ${t('inactivity_days')}` : '-'}
          </div>
          <div className="text-s text-[#BFBFBF]">
            {lastActivityAt &&
              t('last_activity_date', {
                date: format(new Date(lastActivityAt || 0), 'dd/MM/yy'),
              })}
          </div>
        </div>
      </div>
    </Card>
  );
};
