import { Button, ButtonVariant } from '@hubportal/components';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Card = ({
  type,
  badge,
  children,
  onClickDetails,
}: PropsWithChildren<{
  type: 'default' | 'alert';
  badge?: string;
  onClickDetails?: () => void;
}>): JSX.Element => {
  const { t } = useTranslation();

  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`p-6 border-2 w-[18rem] min-h-[12.5rem] shrink-0 rounded-lg cursor-pointer relative transition-opacity ${
        type === 'alert'
          ? 'border-[#D8232A] bg-[rgba(52,25,25,0.50)]'
          : 'border-primary bg-primary'
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={`h-full ${hovered && onClickDetails ? 'opacity-50' : ''}`}
      >
        {children}
      </div>

      {onClickDetails && (
        <div
          className={`absolute inset-0 p-6 rounded-lg flex items-end justify-end transition-opacity ${
            hovered ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <Button variant={ButtonVariant.quinary} onClick={onClickDetails}>
            {t('see_details')}
          </Button>
        </div>
      )}
      {badge && (
        <div className="bg-[#D8232A] text-white title-xs rounded-full px-2 py-1 absolute -top-3 right-4 uppercase">
          {badge}
        </div>
      )}
    </div>
  );
};
